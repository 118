<template>
  <div>
    <div>
      <img
        class="hidden-sm-and-down"
        style="width: 100%"
        src="../../assets/mock/serve/support.jpg"
        alt=""
      />
      <img
        class="hidden-md-and-up"
        style="width: 100%"
        src="../../assets/mock/us/serve.png"
        alt=""
      />
    </div>
    <div class="content warp hidden-sm-and-down" style="min-height: 41.6667vw">
      <el-menu
        style="width: 10.4167vw; min-width: 12.5rem"
        default-active="1"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
      >
        <el-menu-item index="1" @click="$router.push({ path: '/serve' })">
          <template slot="title">
            <span style="font-size: 1.3021vw">售后服务</span>
          </template>
        </el-menu-item>
        <el-menu-item index="2" @click="$router.push({ path: '/serve/issue' })">
          <span slot="title" style="font-size: 1.3021vw">常见问题</span>
        </el-menu-item>
        <el-submenu index="3">
          <span slot="title" style="font-size: 1.3021vw">资料下载</span>
          <el-menu-item-group>
            <el-menu-item
              index="1-1"
              @click="$router.push({ path: '/serve/specification' })"
              ><span style="font-size: 1.0417vw">产品说明书</span></el-menu-item
            >
            <el-menu-item
              index="1-2"
              @click="$router.push({ path: '/serve/instructions' })"
              ><span style="font-size: 1.0417vw"
                >产品安装视频</span
              ></el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
      </el-menu>
      <div class="container">
        <router-view></router-view>
      </div>
    </div>

    <!--  -->
    <div class="warp hidden-md-and-up">
      <el-tabs :tab-position="'top'">
        <el-tab-pane style="font-size: 1.5625rem" label="售后服务">
          <div class="sale">
            <h2>售后服务</h2>
            <ul>
              <li>
                <h2>服务承诺</h2>
                <p>免费送货，一年保修，全程安装咨询</p>
              </li>
              <li>
                <h2>7天无理由退货</h2>
                <p>自收到购买商品7天内无理由退货</p>
              </li>
              <li>
                <h2>30天免费换货</h2>
                <p>
                  购买收货30天内，因质量问题，免费换货<br />
                  智能锁、配件类商品您可以拨打宇起售后电话，与售后工作人员共同完成换货服务。
                </p>
              </li>
              <li>
                <h2>客户回访</h2>
                <p>
                  客户回访是服务的重要一环，重视客户回访，<br />
                  充分利用每次机会，了解更多需求，才能用心服务好每一位客户；<br />
                  对于宇起，不仅仅是一句口号，每一位宇起的客户，交易的完成不代表结束，服务依旧继续。<br />
                </p>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="常见问题">
          <div class="issue">
            <h2>热门问题</h2>
            <div class="issue_top">
              <div v-for="item in problemList" :key="item.article_id">
                <h4>{{ item.title }}</h4>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资料下载">
          <div class="sale">
            <h2>资料下载</h2>
            <ul>
              <li>
                <u
                  ><a
                    href="../../assets/mock/serve/产品说明书.pdf"
                    download="产品说明书.pdf"
                    >产品说明书下载</a
                  ></u
                >
              </li>
              <li>
                <u
                  ><a
                    style="margin-top: 1.25rem; font-weight: 500"
                    href="../../assets/mock/serve/产品宣传.mp4"
                    download="产品宣传.mp4"
                    >产品安装视频下载</a
                  ></u
                ><br />
                <video width="368" controls>
                  <source
                    src="../../assets/mock/serve/产品宣传.mp4"
                    type="video/mp4"
                  />
                </video>
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { getHotProblem } from "../../api/serve";
export default {
  data() {
    return {
      problemList: [],
      screenWidth: document.body.clientWidth,
      nav: "right",
      navWidth: 0,
    };
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        that.screenWidth = window.screenWidth;
      })();
    };
  },
  watch: {
    screenWidth: {
      immediate: true,
      handler: function (newVal) {
        this.navWidth = newVal;
      },
    },
  },
  created() {
    this.getServe();
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    // 热门问题
    getServe() {
      const params = {
        page: 1,
        size: 6,
      };
      getHotProblem(params).then((res) => {
        this.problemList = res.data.code;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.sale {
  text-align: center;
  margin-top: 2.6042vw;
  h2 {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 1.0417vw 0;
  }
  p {
    font-size: 14px;
    line-height: 2;
  }
  li {
    margin: 2.6042vw auto;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 1px solid #ebeef5;
    padding: 4.6042vw 0;
  }
}
.content {
  display: flex;
}
.container {
  margin: 0 auto;
}
.issue {
  text-align: center;
  margin-top: 6.125rem;
  min-height: 20rem;
  h2 {
    font-size: 1.25rem;
    font-weight: 400;
    margin: 5.0417vw 0;
  }
  .issue_top {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    h4 {
      padding: 0.625rem;
      border-bottom: 1px solid #ccc;
    }
  }
}
::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right::after {
  height: 0;
}
::v-deep .el-tabs--right .el-tabs__item.is-right {
  font-size: 1.25rem;
}
</style>
